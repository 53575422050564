<template>
    <div class="content-container design-project">
        <swiper v-bind="swiperOptions" @init="onSwiperInit" ref="mySwiper">
            <swiper-slide>
                <div class="top-section" v-if="activeLang === 'kr'">
                    <h2>Vibrant Design Projects</h2>
                    <p>바이브런트 디자인 프로젝트는 통신 디바이스의 기술적인 진보를 넘어 디바이스를 쓰고, 보고, 느끼는 방식을 혁신합니다. 사용자와 환경을 깊이 관찰하고 공감해서 문제점을 새롭게 바라보고, 창의적이고 지속가능한 아이디어를 제시합니다. 그리고 그것은 사용자의 새로운 통신 디바이스 경험으로 완성됩니다.</p>
                </div>
                <div class="top-section" v-else>
                    <h2>Vibrant Design Projects</h2>
                    <p class="en">Vibrant design projects go beyond technological advancements in communications devices to revolutionize the way we use, see, and feel them. We deeply observe and empathize with users and their environments to take a fresh look at problems and come up with creative and sustainable ideas, culminating in new experiences for users of communications devices.</p>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="main-section">
                    <h3 v-if="activeLang === 'kr'" class="kr">사용자로부터의 출발<span>Start from User’s Perspective</span></h3>
                    <h3 v-else>Start from User’s Perspective</h3>
                    <p v-if="activeLang === 'kr'">바이브런트 디자인 프로젝트는 사용자를 이해하고 공감하는 것부터 시작됩니다. 심층 인터뷰나 관찰, 설문조사 등 다양한 디자인 리서치 툴을 활용해 사용자의 경험, 감정, 동기, 기대 등을 탐색합니다. 이를 통해 사용자의 관점에서 문제를 바라보고, 실제 사용자의 필요와 문제를 심도있게 파악합니다.</p>
                    <p v-else class="en">Vibrant design projects start with understanding and empathizing with our users. We use a variety of design research tools, including in-depth interviews, observations, and surveys, to explore users' experiences, feelings, motivations, and expectations. This allows us to see problems from the user's perspective and gain a deeper understanding of real user needs and problems.</p>
                    <div class="lottie lottie-1"></div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="main-section">
                    <h3 v-if="activeLang === 'kr'" class="kr">문제 정의<span>Define</span></h3>
                    <h3 v-else>Define</h3>
                    <p v-if="activeLang === 'kr'">리서치 단계에서 얻은 인사이트를 바탕으로 문제를 명확히 정의합니다. 이 단계는 문제의 핵심을 파악하고, 프로젝트가 해결해야 할 구체적인 문제점과 목표를 정의합니다. 이때 사용자 관점에서 문제를 재구성하여 창의적이고 혁신적인 해결책을 도출 할 수 있는 기반을 마련합니다.</p>
                    <p v-else class="en">Clarify the problem based on the insights gained in the research phase. This step gets to the heart of the matter and defines the specific problems and goals that the project needs to solve. This is where we reframe the problem from the user's perspective, laying the groundwork for creative and innovative solutions.</p>
                    <div class="lottie lottie-2"></div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="main-section">
                    <h3 v-if="activeLang === 'kr'" class="kr">아이디어 도출<span>Ideation</span></h3>
                    <h3 v-else>Ideation</h3>
                    <p v-if="activeLang === 'kr'">기존 데이터와 목표를 토대로 창의적인 아이디어를 자유롭게 제시합니다. 브레인스토밍, 스케치, 스토리보드 등 생각과 관념을 시각화 할 수 있는 다양한 디자인 툴을 사용합니다. 이 단계는 문제 해결의 가능성을 넓히며, 비선형적인 접근을 통해 디자인 혁신에 접근합니다.</p>
                    <p v-else class="en">Feel free to generate creative ideas based on existing data and goals. Use a variety of design tools to visualize your thoughts and ideas, including brainstorming, sketching, and storyboarding. This step expands the possibilities for problem solving and takes a non-linear approach to design innovation.</p>
                    <div class="lottie lottie-3"></div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="main-section">
                    <h3 v-if="activeLang === 'kr'" class="kr">프로토타입<span>Prototype</span></h3>
                    <h3 v-else>Prototype</h3>
                    <p v-if="activeLang === 'kr'">아이디어를 구체화 합니다. 디자이너 뿐만 아니라 내외부의 엔지니어, 마케터, 재무담당자 등 다양한 이해관계자와 소통하며 아이디어를 공유합니다. 이때 디자인 스케치, 간단한 3D 목업, 형태가 갖추어진 디자인 목업 등 손에 잡히는 디자인 결과물을 활용해 효과적으로 피드백을 얻어, 실증적으로 아이디어를 검증합니다.</p>
                    <p v-else class="en">Refine ideas. Communicate and share ideas with a variety of stakeholders, including engineers, marketers, and finance, both internally and externally, in addition to designers. We use handheld design deliverables, such as design sketches, simple 3D mockups, and fully formed design mockups, to effectively get feedback and validate ideas empirically.</p>
                    <div class="lottie lottie-4"></div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="main-section">
                    <h3 v-if="activeLang === 'kr'" class="kr">상용 디자인<span>Deliver and Repeat</span></h3>
                    <h3 v-else>Deliver and Repeat</h3>
                    <p v-if="activeLang === 'kr'">프로토타입을 더욱 구체화하여 상용 검토를 합니다. 심미적인 만족감과 사용편의성 향상과 더불어 비용효율적인 구현 방식에 대한 심도 깊은 논의를 진행합니다. 이 단계는 반복적으로 수행되며, 최종 디자인과 기는을 지속적으로 개선합니다.</p>
                    <p v-else class="en">The prototype is further refined for commercial consideration, with in-depth discussions on how to make it cost-effective to implement while improving aesthetics and usability. This step is iterative, and we continuously refine the final design and creep.</p>
                    <div class="lottie lottie-5"></div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="main-section">
                    <div class="horizon">
                        <swiper v-bind="horizonSwiperOptions">
                            <swiper-slide>
                                <div>
                                    <span>All-in-One Soundbar</span>
                                    <img src="@/assets/img/design_project/project-1.jpg" />
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div>
                                    <span>High Order 2</span>
                                    <img src="@/assets/img/design_project/project-2.jpg" />
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div>
                                    <span>WiFi 6D</span>
                                    <img src="@/assets/img/design_project/project-3.jpg" />
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Mousewheel } from 'swiper/modules';
import 'swiper/css/pagination';
import lottie from 'lottie-web';
import animationData1 from '@/assets/lottie/01_start.json';
import animationData2 from '@/assets/lottie/02_solve.json';
import animationData3 from '@/assets/lottie/03_idea.json';
import animationData4 from '@/assets/lottie/04_prototype.json';
import animationData5 from '@/assets/lottie/05_design.json';

export default {
    name: 'AppDesignProject',
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            activeLang: sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'kr',
            swiperOptions: {
                direction: 'vertical',
                slidesPerView: 1.5,
                allowTouchMove: true,
                speed: 700,
                modules: [Pagination, Mousewheel],
                slideToClickedSlide: false,
                mousewheel: true,
            },
            horizonSwiperOptions: {
                width: window.innerWidth > 1024 ? (window.innerHeight - 105) / 1.5 - 48 + 18 : window.innerWidth * 0.9,
                direction: 'horizontal',
                slidesPerView: 1,
                allowTouchMove: true,
                speed: 700,
            },
        };
    },
    methods: {
        receiveLang(lang) {
            this.activeLang = lang;
        },
        handleResize() {
            this.horizonSwiperOptions.width = window.innerWidth > 1024 ? (window.innerHeight - 105) / 1.5 - 48 + 18 : window.innerWidth * 0.9;
            if (window.innerWidth <= 1024) {
                this.swiperOptions.slidesPerView = 1;
            } else {
                this.swiperOptions.slidesPerView = 1.5;
            }
        },
        onSwiperInit(swiper) {
            window.addEventListener('resize', () => {
                swiper.update();
                this.handleResize();
            });
            swiper.on('slideChange', () => {
                if (swiper.activeIndex === 0) {
                    document.querySelector('html').dataset.page = 'vibrant-design-project';
                } else {
                    document.querySelector('html').dataset.page = '';
                }
            });
        },
        loadLottieAnimation(animationData, containerSelector) {
            lottie.loadAnimation({
                container: document.querySelector(containerSelector),
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animationData,
            });
        },
    },
    mounted() {
        document.querySelector('html').dataset.page = 'vibrant-design-project';

        this.loadLottieAnimation(animationData1, '.lottie-1');
        this.loadLottieAnimation(animationData2, '.lottie-2');
        this.loadLottieAnimation(animationData3, '.lottie-3');
        this.loadLottieAnimation(animationData4, '.lottie-4');
        this.loadLottieAnimation(animationData5, '.lottie-5');

        if (window.innerWidth <= 1024) {
            this.swiperOptions.slidesPerView = 1;
        }
    },
    beforeMount() {
        this.emitter.on('lang', this.receiveLang);
    },
};
</script>

<style lang="scss">
#content {
    .content-container.design-project {
        padding: 6.5rem 0 0;
        .top-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            max-width: 40rem;
            height: 100%;
            margin: 0 auto;
            padding: 0 1.125rem;
            h2 {
                margin: 0 0 1.5rem;
                font-size: 3rem;
                color: #fff;
                font-weight: 500;
            }
            p {
                color: #fff;
                font-size: 1.125rem;
                line-height: 1.65;
                &.en {
                    line-height: 1.55;
                }
            }
        }
        .main-section {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            max-width: 1920px;
            height: 100%;
            margin: 0 auto;
            padding: 1.5rem 1.125rem;
            h3 {
                grid-column: 1/5;
                color: #000;
                margin: 0 0 2.5rem;
                font-size: 1.9875rem;
                font-weight: 500;
                line-height: 1.375;
                span {
                    display: block;
                }
                &.kr {
                    font-size: 1.875rem;
                    font-weight: 600;
                    span {
                        font-size: 1.9875rem;
                        font-weight: 500;
                    }
                }
            }
            p {
                grid-column: 1/4;
                margin: 0 0 1rem;
                color: #787878;
                line-height: 1.55;
                &.en {
                    line-height: 1.45;
                }
            }
            .lottie {
                grid-column: 6/13;
                height: 100%;
                overflow: hidden;
                svg {
                    object-fit: contain;
                    object-position: center bottom;
                }
            }
            &:has(.horizon) {
                left: 50%;
                padding: 1.5rem 0;
                margin: 0;
                transform: translateX(calc(((100 * var(--vh) - 105px) / 1.5 - 48px) / -2));
            }
            .horizon {
                .swiper {
                    display: flex;
                    align-items: center;
                }
                .swiper-wrapper {
                    display: flex;
                }
                .swiper-slide {
                    background-color: transparent;
                    div {
                        margin-right: 1.125rem;
                        border: 1px solid #c6c6c6;
                        border-radius: 1rem;
                        aspect-ratio: 1;
                        overflow: hidden;
                    }
                    span {
                        position: absolute;
                        top: 2.5rem;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }
            .swiper {
                overflow: visible;
            }
        }
        .swiper {
            &::after {
                display: none;
            }
        }
        .swiper-slide {
            background-color: #fff;
            &:nth-child(1) {
                background-color: #000;
            }
            &:nth-child(2) {
                h3 {
                    color: #7000ff;
                }
            }
            &:nth-child(3) {
                background-color: #f9f9f9;
                h3 {
                    color: #fd6340;
                }
            }
            &:nth-child(4) {
                h3 {
                    color: #1ee49d;
                }
            }
            &:nth-child(5) {
                background-color: #f9f9f9;
                h3 {
                    color: #34b6ff;
                }
            }
            &:nth-child(6) {
                h3 {
                    color: #ff4dab;
                }
            }
            &:nth-child(7) {
                background-color: #f9f9f9;
            }
        }
    }
}
@media (max-width: 1024px) {
    #content {
        .content-container.design-project {
            padding-top: calc((100vw - 2.25rem) / (1400 / 368) + 3.625rem);
            padding-top: 0;
            .top-section {
                max-width: 50rem;
                padding: 0 2rem;
                h2 {
                    margin-top: calc(((100vw - 1.5rem) / 3.8043478261 + 2.25rem) / -2);
                }
                p {
                    font-size: 1.125rem;
                }
            }
            .main-section {
                grid-template-rows: auto auto 1fr;
                h3 {
                    grid-column: 1/13;
                    margin-bottom: 1rem;
                    line-height: 1.4;
                }
                p {
                    grid-column: 1/13;
                    font-size: 1.125rem;
                }
                .lottie {
                    grid-column: 1/13;
                    grid-row: auto;
                    height: 100%;
                    margin: auto -1.125rem 0;
                }
                &:has(.horizon) {
                    left: 0;
                    transform: unset;
                    padding: 1.5rem 1.125rem;
                }
                .horizon {
                    height: auto;
                    .swiper {
                        align-items: flex-start;
                    }
                    .swiper-slide {
                        max-width: calc(100 * var(--vh) - ((100vw - 2.25rem) / 3.8043478261 + 3.625rem) - 3rem + 1.125rem);
                        padding-top: 0;
                    }
                }
            }
            .swiper-slide {
                padding-top: calc((100vw - 2.25rem) / (1400 / 368) + 3.625rem);
                &:nth-child(3),
                &:nth-child(5),
                &:nth-child(7) {
                    background-color: transparent;
                }
                &:nth-child(8) {
                    display: none;
                    background-color: transparent;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    #content {
        .content-container.design-project {
            .top-section {
                h2 {
                    font-size: 2.25rem;
                }
            }
            .main-section {
                padding: 0 0.75rem;
                h3 {
                    font-size: 1.6rem;
                }
                p {
                    grid-column: 1/13;
                }
            }
        }
    }
}
@media (max-width: 480px) {
    #content {
        .content-container.design-project {
            .top-section {
                h2 {
                    font-size: 1.875rem;
                }
            }
        }
    }
}
</style>
