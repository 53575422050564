<template>
    <swiper v-bind="swiperOptions" @init="onSwiperInit">
        <navigation v-if="isMobile" :isMobile="isMobile" :isMain="true" />
        <swiper-slide>
            <div class="main-slide-wrapper">
                <slide v-if="!isMobile" :vh="vh" :vw="vw" @handle-mousewheel="handleMousewheel" />
                <widget v-if="isMobile" :vh="vh" :vw="vw" :isMobile="isMobile" />
            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="main-content-wrapper">
                <navigation :isMobile="isMobile" />
                <router-view></router-view>
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Mousewheel, Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import Slide from '@/components/Slide.vue';
import Navigation from '@/components/Navigation.vue';
import Widget from '@/components/Widget.vue';

export default {
    name: 'AppSwiper',
    props: {
        vh: Number,
        vw: Number,
        isMobile: Boolean,
    },
    components: {
        Slide,
        Swiper,
        SwiperSlide,
        Navigation,
        Widget,
    },
    computed: {
        swiperOptions() {
            return {
                speed: 700,
                direction: 'vertical',
                slidesPerView: 1,
                mousewheel: true,
                allowTouchMove: true,
                modules: [Mousewheel, Pagination],
            };
        },
    },
    data() {
        return {
            swiperInstance: null,
        };
    },
    setup() {
        return {
            modules: [Mousewheel, Pagination],
        };
    },
    methods: {
        handleMousewheel(value) {
            if (value == 'disallow') {
                this.swiperInstance.mousewheel.disable();
            } else {
                if (this.swiperInstance.activeIndex !== 1) {
                    this.swiperInstance.mousewheel.enable();
                }
            }
        },
        onSwiperInit(swiper) {
            const logo = document.querySelector('.main-logo');
            const wrapper = document.querySelector('.swiper');
            this.swiperInstance = swiper;

            swiper.on('slideChange', () => {
                if (swiper.activeIndex === 1) {
                    swiper.mousewheel.disable();
                    swiper.allowTouchMove = false;

                    setTimeout(() => {
                        if (this.$route.path === '/') {
                            this.$router.push('/skin-collaboration');
                        }
                    }, 10);

                    if (logo) {
                        logo.classList.add('down');
                    }
                    if (wrapper) {
                        this.$nextTick(() => {
                            wrapper.classList.add('content-show');
                        });
                    }
                } else {
                    swiper.mousewheel.enable();
                    swiper.allowTouchMove = true;

                    if (logo) {
                        logo.classList.remove('down');
                    }
                    if (wrapper) {
                        wrapper.classList.remove('content-show');
                    }
                }
            });
        },
        receiveClick(value) {
            if (this.swiperInstance) {
                this.swiperInstance.slideTo(value);
            }
        },
    },
    mounted() {
        this.emitter.on('click', this.receiveClick);

        if (window.location.pathname !== '/') {
            this.swiperInstance.slideTo(1, 0);
        }
    },
};
</script>

<style lang="scss">
.swiper {
    height: 100%;
    overflow: hidden;
    .main-slide-wrapper {
        min-height: calc(100 * var(--vh));
        height: calc(100 * var(--vh));
        overflow: hidden;
    }
    .main-content-wrapper {
        min-height: calc(100 * var(--vh));
        height: calc(100 * var(--vh));
        overflow: hidden;
        .content-container {
            height: calc(100 * var(--vh));
            padding: calc(6.5rem + 1.125rem) 1.125rem 1.125rem;
            overflow-y: scroll;
        }
    }
}
@media (max-width: 1024px) {
    .swiper {
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
            transition: opacity 0.7s;
            z-index: -1;
            background: linear-gradient(transparent 60%, rgba(0, 0, 0, 0.075) 95%);
        }
        &.content-show::after {
            opacity: 0;
        }
        .main-content-wrapper,
        .main-slide-wrapper {
            .content-container {
                padding-top: calc((100vw - 2.25rem) / (1400 / 368) + 3.625rem);
                .list-box,
                .grid-box {
                    padding-top: 5rem !important;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .swiper {
        .main-content-wrapper,
        .main-slide-wrapper {
            .content-container {
                padding-top: calc((100vw - 1.5rem) / (1400 / 368) + 2.25rem);
                padding-left: 0.75rem;
                padding-right: 0.75rem;
                .list-box {
                    padding-top: 3.25rem !important;
                }
            }
        }
    }
}
</style>
