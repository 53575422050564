<template>
    <div class="content-container" ref="Container" @scroll="handleScroll">
        <div class="list-box">
            <div v-for="(post, index) in posts" :key="post.id" class="post-item" @click="openPopup(post, index)">
                <router-link :to="'/skin-collaboration/' + post.slug" class="item-inner">
                    <div class="thumbnail-wrapper">
                        <img :src="post.featured_image" alt="Post Image" v-if="post.featured_image" />
                    </div>
                    <strong class="post-title">{{ post.title.rendered }}</strong>
                </router-link>
            </div>
        </div>
    </div>
    <div class="popup-wrapper" :class="{ open: isPopupOpen }" @click="handleClick" ref="popupWrapper">
        <post-popup :post="currentPost" @prev-post="goToPrevPost" @next-post="goToNextPost" @close-popup="closePopup" />
    </div>
    <toggle-view :class="{ scrolling: mobileScrolling }" />
</template>
<script>
import PostPopup from '@/components/PostPopup.vue';
import ToggleView from '@/components/ToggleView.vue';

export default {
    name: 'AppArtist',
    props: {
        clickedArtistSlug: String,
    },
    components: { PostPopup, ToggleView },
    data() {
        return {
            server: 'https://vibrantwifiproject.com/wp',
            perPage: '100',
            posts: [],
            currentPost: null,
            currentPostIndex: null,
            isPopupOpen: false,
            lastScroll: 0,
            scrollCount: 0,
            scrollLimit: 100,
            mobileScrolling: false,
            isMobile: false,
        };
    },
    methods: {
        checkScreenSize() {
            this.isMobile = window.innerWidth <= 1024;
        },
        handleClick(e) {
            if (e.target == this.$refs.popupWrapper) {
                this.closePopup();
            }
        },
        handleKeydown(e) {
            if (e.key === 'Escape' || e.keyCode === 27) {
                if (document.documentElement.classList.contains('with-fancybox')) return;
                this.closePopup();
            }
        },
        openPopup(post, index) {
            this.currentPost = post;
            this.currentPostIndex = index;
            this.isPopupOpen = true;
            history.pushState({}, '', `/skin-collaboration/${post.slug}`);

            this.emitter.emit('popupOpen', this.isPopupOpen);
        },
        closePopup() {
            this.$refs.popupWrapper.style = 'animation: OpacityToZero 0.5s forwards';
            document.querySelector('.popup').style = 'animation: slideFromTop 0.5s forwards !important';
            setTimeout(() => {
                this.isPopupOpen = false;
                document.querySelector('.popup').style.animation = 'unset';
                this.$refs.popupWrapper.style = 'unset';
                this.emitter.emit('popupOpen', this.isPopupOpen);
            }, 500);
            history.pushState({}, '', '/skin-collaboration/artist');

            this.$refs.popupWrapper.scrollTo(0, 0);
        },
        goToPrevPost() {
            if (this.currentPostIndex > 0) {
                this.currentPostIndex--;
            } else {
                // 마지막 포스트로 이동
                this.currentPostIndex = this.posts.length - 1;
            }

            const newPost = this.posts[this.currentPostIndex];
            const correspondingTransformedPost = this.posts.find((p) => p.index === this.currentPostIndex);
            this.currentPost = correspondingTransformedPost;
            history.pushState({}, '', `/skin-collaboration/${newPost.slug}`);

            this.$refs.popupWrapper.scrollTo(0, 0);
        },
        goToNextPost() {
            if (this.currentPostIndex < this.posts.length - 1) {
                this.currentPostIndex++;
            } else {
                // 첫 번째 포스트로 이동
                this.currentPostIndex = 0;
            }

            const newPost = this.posts[this.currentPostIndex];
            const correspondingTransformedPost = this.posts.find((p) => p.index === this.currentPostIndex);
            this.currentPost = correspondingTransformedPost;
            history.pushState({}, '', `/skin-collaboration/${newPost.slug}`);

            this.$refs.popupWrapper.scrollTo(0, 0);
        },
        handleToggleViewByScroll(scroll) {
            let delta = scroll - this.lastScroll;

            if (delta > 0 && this.scrollCount < 0) this.scrollCount = 0;
            if (delta < 0 && this.scrollCount > 0) this.scrollCount = 0;
            this.scrollCount = Math.min(Math.max(this.scrollCount + delta, -this.scrollLimit), this.scrollLimit);

            if (this.scrollCount === this.scrollLimit && !this.mobileScrolling) {
                this.mobileScrolling = true;
            } else if (this.scrollCount === -this.scrollLimit && this.mobileScrolling) {
                this.mobileScrolling = false;
            }

            this.lastScroll = scroll;
        },
        async getPosts() {
            return fetch(`${this.server}/wp-json/wp/v2/posts?per_page=${this.perPage}&_embed`, { method: 'GET' })
                .then((response) => {
                    return response.json();
                })
                .then((res) => {
                    this.posts = res.map((post, index) => {
                        let featured_image = null;
                        if (post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0] && post._embedded['wp:featuredmedia'][0].source_url) {
                            featured_image = post._embedded['wp:featuredmedia'][0].source_url;
                        }
                        return {
                            ...post,
                            featured_image: featured_image,
                            index: index,
                            thumbnailIndex: 0,
                        };
                    });
                });
        },
        handleScroll() {
            this.handleToggleViewByScroll(this.$refs.Container.scrollTop);
        },
    },
    mounted() {
        document.querySelector('html').dataset.page = 'skin-collaboration';

        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
        document.addEventListener('keydown', this.handleKeydown);

        this.getPosts().then(() => {
            if (this.clickedArtistSlug) {
                // url로 직접 접근시 해당 슬러그 팝업을 열기
                const postWithGivenSlug = this.posts.find((post) => post.slug === this.clickedArtistSlug);
                if (postWithGivenSlug) {
                    const postIndex = this.posts.indexOf(postWithGivenSlug);
                    this.openPopup(postWithGivenSlug, postIndex);
                }
            }
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkScreenSize);
        document.removeEventListener('keydown', this.handleKeydown);
    },
};
</script>

<style lang="scss">
.list-box {
    display: grid;
    grid-template-rows: repeat(11, 1fr);
    min-height: calc(100 * var(--vh) - 6.5rem);
    margin: -1.125rem;
    .post-item {
        grid-column: 1/3;
        width: 100%;
        border-bottom: 1px solid var(--main-color);
        cursor: pointer;
        &:hover {
            .item-inner {
                .thumbnail-wrapper img {
                    opacity: 1;
                    transition: opacity 0.15s;
                }
                .post-title {
                    color: #fff;
                    mix-blend-mode: difference;
                }
            }
        }
        .item-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            pointer-events: none;
            .thumbnail-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    opacity: 0;
                    transition: opacity 0.15s;
                }
            }
            .post-title {
                position: relative;
                padding: 0.75rem;
                font-size: max(6vh, 3.75rem);
                line-height: 1;
                text-align: center;
                z-index: 1;
            }
        }
    }
}
@media (max-width: 1024px) {
    .list-box {
        min-height: calc((100 * var(--vh)) - ((100vw - 2.25rem) / 3.8043478261 + 2.5rem));
        .post-item {
            &:first-child {
                border-top: 1px solid var(--main-color);
            }
            .item-inner {
                .post-title {
                    font-size: max(5vh, 3.75rem);
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .list-box {
        min-height: calc((100 * var(--vh)) - ((100vw - 1.5rem) / 3.8043478261 + 2.25rem));
        margin: 0 -0.75rem -1.125rem;
        .post-item {
            .item-inner {
                .post-title {
                    padding: 0.75rem;
                    font-size: max(5vh, 2.5rem);
                }
            }
            &:hover {
                .item-inner .thumbnail-wrapper img {
                    opacity: 0;
                }
            }
        }
    }
}
@media (max-width: 480px) {
    .list-box {
        .post-item {
            .item-inner {
                .post-title {
                    font-size: 9vw;
                }
            }
        }
    }
}
</style>
