<template>
    <div id="content" class="content">
        <div v-if="notSupport" class="notsupport">
            <img src="@/assets/img/logo.svg" alt="vibrant wifi" v-if="isMobile" />
            <p>가로 모드를 지원하지 않습니다.</p>
        </div>
        <swiper v-else :vh="vh" :vw="vw" :isMobile="isMobile" />
    </div>
</template>

<script>
import Swiper from '@/views/Swiper.vue';

export default {
    name: 'App',
    components: {
        Swiper,
    },
    data() {
        return {
            vh: window.innerHeight * 0.01,
            vw: window.innerWidth * 0.01,
            isMobile: window.innerWidth <= 1024,
            notSupport: window.innerWidth > 1024 ? false : window.innerWidth / window.innerHeight > 1.4,
        };
    },
    methods: {
        setVh() {
            this.vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${this.vh}px`);
        },
        setVw() {
            this.vw = window.innerWidth * 0.01;
            document.documentElement.style.setProperty('--vw', `${this.vw}px`);
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth <= 1024;
        },
        checkScreenRatio() {
            this.notSupport = !this.isMobile ? false : window.innerWidth / window.innerHeight > 1.4;
        },
        checkScreenRetina() {
            let isRetina = false;
            if (window.matchMedia) {
                let mediaQuery = '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
                isRetina = window.matchMedia(mediaQuery).matches;
            } else {
                isRetina = window.devicePixelRatio > 1;
            }

            const htmlElement = document.documentElement;

            if (isRetina) {
                htmlElement.setAttribute('data-ratio', 'retina');
            } else {
                htmlElement.setAttribute('data-ratio', 'non-retina');
            }
        },
    },
    mounted() {
        this.setVw();
        this.setVh();
        this.checkScreenRetina();

        window.addEventListener('touchend', () => {
            this.setVw();
            this.setVh();
        });
        window.addEventListener('resize', () => {
            this.setVw();
            this.setVh();
            this.checkScreenSize();
            this.checkScreenRatio();
            this.checkScreenRetina();
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.setVh);
        window.removeEventListener('touchend', this.setVh);
        window.removeEventListener('resize', this.setVw);
        window.removeEventListener('touchend', this.setVw);

        window.removeEventListener('resize', this.checkScreenSize);
    },
};
</script>

<style lang="scss">
* {
    box-sizing: border-box;
    word-break: keep-all;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-overflow-style: none;
    scrollbar-width: none;
    touch-action: pan-x pan-y;
    &::-webkit-scrollbar {
        display: none;
    }
}

html {
    font-size: 16px;
    @media (min-width: 1920px) {
        font-size: 18px;
    }
    @media (max-width: 1024px) {
        font-size: 15px;
    }
    @media (max-width: 480px) {
        font-size: 14px;
    }
}

:root {
    --primary-color: #0062ff;
    --background-color: #fff;
    --main-color: #000;
    --filter: unset;
}
:root[data-page='kt-wifi-6d'],
:root[data-page='vibrant-design-project'] {
    --primary-color: #0062ff;
    --background-color: #000;
    --main-color: #fff;
    --filter: invert(100%);
}
@media (max-width: 1024px) {
    :root[data-page='skin-collaboration'] {
        --primary-color: #0062ff;
        --background-color: #000;
        --main-color: #fff;
        --filter: invert(100%);
    }
}

body {
    width: 100vw;
    height: calc(var(--vh) * 100);
    min-height: calc(var(--vh) * 100);
    margin: 0;
    color: var(--main-color);
    background-color: var(--background-color);
    transition: background-color 0.25s;
    font-family: 'EP Gothic', 'EP Gothic kr', 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    font-weight: 500;
    overflow-x: hidden;
    #app,
    #content {
        height: 100%;
    }
    div {
        position: relative;
    }
    a {
        color: var(--main-color);
        text-decoration: none;
    }
    strong {
        font-weight: 500;
    }
    button {
        padding: 0;
        background-color: transparent;
        border: 0;
    }
    ::selection {
        background-color: #d9d9d9;
        color: #000;
    }
    video {
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
    }
    .hidden {
        overflow: hidden;
        position: absolute;
        width: 0;
        height: 0;
        line-height: 0;
        text-indent: -9999px;
    }
    h1,
    h2,
    h3,
    p,
    strong,
    button,
    a,
    span {
        transition: font-size 0.1s;
    }
    .notsupport {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0.75rem;
        img {
            width: 100%;
        }
        p {
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 1.25rem;
        }
    }
}
</style>
