import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router.js';
import store from '@/store';

import mitt from 'mitt';
import VueKonva from 'vue-konva';

import '@/assets/fonts/font.css';

const emitter = mitt();
const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueKonva);

app.config.globalProperties.emitter = emitter;

app.mount('#app');
