<template>
    <div></div>
</template>

<script>
export default {
    name: 'AppMain',
};
</script>

<style lang="scss">
#content {
}
</style>
