<template>
    <div class="main-logo">
        <img src="@/assets/img/logo.svg" class="logo" />
    </div>
    <div class="main-slide" @click="notifyClick">
        <v-stage ref="stage" :config="{ x: 0, y: 0, width: vw * 100, height: vh * 100 }">
            <v-layer ref="layer">
                <v-image :config="{ image: src, x: 0, y: 0, width: vh * 100, height: vh * 100 }" />
            </v-layer>
        </v-stage>
    </div>
    <div class="slide-navigation" @mouseleave="handleNavOut" @mouseenter="$emit('handle-mousewheel', 'disallow')" ref="slideNavigation">
        <div v-for="(n, index) in randomOrder" :key="n" :data-id="n" @mouseover="handleNav" @click="loadImage" :class="{ active: n == selected, near: index == randomOrder.indexOf(Number(selected)) - 1 || index == randomOrder.indexOf(Number(selected)) + 1 }" class="nav-item">
            <img :src="require(`@/assets/img/thumbnail/thumbnail_${n}.jpg`)" alt="Thumbnail" />
        </div>
    </div>
</template>

<script>
import parseAPNG from 'apng-js';
import Konva from 'konva';

export default {
    name: 'AppSlide',
    props: {
        vh: Number,
        vw: Number,
    },
    emits: ['handle-mousewheel'],
    data() {
        return {
            src: null,
            animation: null,
            currentPlayer: null,
            selected: null,
            numOfSources: 38,
            lastClicked: null,
        };
    },
    computed: {
        randomOrder() {
            let array = Array.from({ length: this.numOfSources }, (_, i) => i + 1);
            for (let i = array.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        },
    },
    methods: {
        handleNavOut() {
            this.$emit('handle-mousewheel', 'allow');
            this.selected = this.lastClicked;
        },
        handleScroll(e) {
            e.preventDefault();
            if (e.deltaY) {
                this.$refs.slideNavigation.scrollLeft += e.deltaY + e.deltaX;
            }
        },
        handleNav(e) {
            const id = e.currentTarget.dataset.id;
            this.selected = id;
        },
        notifyClick() {
            this.emitter.emit('click', 1);
        },
        restoreLastClickedNav() {
            if (this.lastClicked) {
                this.selected = this.lastClicked;
            }
        },
        async loadImage(e) {
            if (this.currentPlayer) {
                this.currentFrame = this.currentPlayer.currentFrameNumber;
            }
            const id = e.currentTarget ? e.currentTarget.dataset.id : '1';
            await this.loadAPNGImage(id);
            this.selected = id;
            this.lastClicked = id;
        },
        async loadAPNGImage(id) {
            const START_FRAME = this.currentPlayer ? this.currentPlayer.currentFrameNumber : 0;
            const pngPath = require(`@/assets/img/apng/animation_${id}.png`);

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const layer = this.$refs.layer.getNode();

            if (this.animation) {
                this.animation.stop();
            }
            if (this.currentPlayer) {
                this.currentPlayer.pause();
            }

            try {
                const buffer = await this.getImgBuffer(pngPath);
                const apng = parseAPNG(buffer);
                const player = await apng.getPlayer(ctx);

                canvas.width = apng.width;
                canvas.height = apng.height;

                if (this.currentPlayer && this.currentPlayer !== player) {
                    this.currentPlayer.stop();
                }

                this.currentPlayer = player;

                for (let i = 0; i < START_FRAME; i++) {
                    player.renderNextFrame();
                }

                player.play();

                this.src = canvas;
                this.animation = new Konva.Animation(() => {}, layer);
                this.animation.start();

                this.setPosition(layer, id);
                window.addEventListener('resize', () => {
                    this.setPosition(layer, id);
                });
            } catch (error) {
                console.error('Error loading the image:', error);
            }
        },
        async getImgBuffer(url) {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(blob);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (err) => reject(err);
            });
        },
        setPosition(layer, id) {
            if (layer.children.length > 0) {
                layer.children[0].x(layer.width() / 2);
                layer.children[0].offsetX(layer.children[0].width() / 2);
                layer.children[0].y(layer.height() / 2);
                layer.children[0].offsetY(layer.children[0].height() / 2);

                layer.children[0].y(layer.children[0].height() / 1.7);
                layer.children[0].scale({
                    x: 0.7,
                    y: 0.7,
                });

                if (id == 13 || id == 14 || id == 15 || id == 16) {
                    // 아티스트
                    layer.children[0].y(layer.children[0].height() / 2);
                    layer.children[0].scale({
                        x: 0.9,
                        y: 0.9,
                    });
                }
                if (id == 11 || id == 12) {
                    // 스커트 쇼트
                    layer.children[0].y(layer.children[0].height() / 2.35);
                    layer.children[0].scale({
                        x: 1.1,
                        y: 1.1,
                    });
                }
                if (id == 10) {
                    // 스커트 롱
                    layer.children[0].y(layer.children[0].height() / 2);
                    layer.children[0].scale({
                        x: 0.85,
                        y: 0.85,
                    });
                }
                if (id == 6 || id == 7) {
                    // 가로형
                    layer.children[0].y(layer.children[0].height() / 1.7);
                    layer.children[0].scale({
                        x: 1.1,
                        y: 1.1,
                    });
                }
            }
        },
    },
    mounted() {
        const randomRange = window.innerWidth > 1900 ? this.numOfSources : Math.floor(window.innerWidth / 50);
        const randomId = Math.floor(Math.random() * randomRange) + 1;

        this.selected = randomId;
        this.lastClicked = randomId;

        this.loadAPNGImage(randomId);

        this.$refs.slideNavigation.addEventListener('wheel', this.handleScroll, { passive: false });
    },
    beforeUnmount() {
        this.$refs.slideNavigation.removeEventListener('wheel', this.handleScroll);

        window.removeEventListener('resize', this.setPosition);
    },
};
</script>

<style lang="scss">
#content {
    .main-slide {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        .konvajs-content {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            z-index: -1;
            canvas {
                position: relative !important;
            }
        }
    }
    .main-logo {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        padding: 1.125rem;
        img {
            width: 100%;
            transition: all 0.4s;
        }
        &.down {
            img {
                transform: translateY(calc(var(--vh) * 70));
                width: 20%;
                transition: all 0.6s;
            }
        }
    }
    .slide-navigation {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 0.5rem;
        width: 100%;
        padding: 0.5rem;
        overflow-x: auto;
        z-index: 1;
        .nav-item {
            width: 3vw;
            max-width: 55px;
            min-width: 40px;
            overflow: hidden;
            transition: all 0.3s;
            cursor: pointer;
            aspect-ratio: 1;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            &.near {
                width: 4.5vw;
                max-width: 85px;
                min-width: 55px;
            }
            &.active {
                width: 6vw;
                max-width: 115px;
                min-width: 75px;
            }
        }
    }
}
@media (max-width: 768px) {
    #content {
        .main-slide {
            .konvajs-content {
                canvas {
                    width: 60% !important;
                    height: 60% !important;
                }
            }
        }
    }
}
</style>
