import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/views/Main.vue';
import DesignProject from '@/views/DesignProject.vue';
import Original from '@/views/Original.vue';
import KTWifi6D from '@/views/KTWifi6D.vue';
import Artist from '@/views/Artist.vue';
import SkinCollaboration from '@/views/SkinCollaboration.vue';
import Post from '@/views/Post.vue';

const routes = [
    {
        path: '/',
        component: Main,
        name: 'Main',
    },
    {
        path: '/original',
        component: Original,
        name: 'Original',
    },
    {
        path: '/vibrant-design-project',
        component: DesignProject,
        name: 'DesignProject',
    },
    {
        path: '/kt-wifi-6d',
        component: KTWifi6D,
        name: 'KTWifi6D',
    },
    {
        path: '/skin-collaboration',
        component: SkinCollaboration,
        name: 'SkinCollaboration',
    },
    {
        path: '/skin-collaboration/artist',
        component: Artist,
        name: 'Artist',
    },
    {
        path: '/skin-collaboration/:slug',
        component: Post,
        name: 'Post',
    },
];

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
